import React, {useState} from 'react';
import './Header.css';
import { cx } from '../../../Shared/utils/cx.ts';
import LogoImage from '../../../Shared/images/lib/LogoImage.tsx';
import HeaderImage from '../../../Shared/images/lib/HeaderImage.tsx';
import WaitlistModal from '../WaitlistModal/WaitlistModal.js';


export default function Header(){
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <div className='header-container'>
            {/* Your header content goes here */}
            <div className='header-top'>
                <LogoImage />
                <button className='header-button' onClick={() => setIsModalOpen(true)}>
                    Join waitlist
                </button>
            </div>
            <div className='header-content'>
                <div className='header-grid-left'>
                    <div className={cx('h1', 'header-content-title')}>
                        Transforming How You Learn and Research
                    </div>
                    <div className='header-content-text'>
                        The AI-Powered All-in-One Academic Tool: 
                        From A to Z, Master Your Research and Studies
                    </div>
                    <button className='header-button' onClick={() => setIsModalOpen(true)}>
                        Get started
                    </button>
                </div>
                <div className='header-grid-right'>
                    <HeaderImage className="header-image"
                    />
                </div>

            </div>
            <WaitlistModal show={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>



    );
}
