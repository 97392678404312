import React from 'react';
import './UserFeedbackCard.css';
import BerkeleyLogoImage from '../../../Shared/images/lib/BerkeleyLogoImage.tsx';
import UofTLogoImage from '../../../Shared/images/lib/UofTLogoImage.tsx';
import NYULogoImage from '../../../Shared/images/lib/NYULogoImage.tsx';
import UOttawaLogoImage from '../../../Shared/images/lib/UOttawaLogoImage.tsx';
import GoldenStarIcon from '../../../Shared/icons/lib/GoldenStarIcon.tsx'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProfileImage from '../../../Shared/images/lib/ProfileImage.tsx'
import WaynePfpImage from '../../../Shared/images/lib/WaynePfpImage.tsx';
import GilbertPfpImage from '../../../Shared/images/lib/GilbertPfpImage.tsx';
import HaerinPfpImage from '../../../Shared/images/lib/HaerinPfpImage.tsx';
import ClairePfpImage from '../../../Shared/images/lib/ClairePfpImage.tsx';




export default function UserFeedbackCard() {

    


    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 4,
        infinite: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 660,
                settings: {
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    };

    const renderGoldenStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <GoldenStarIcon
                    key={i}
                    width={16}
                    height={16}
                />
            );
        }
        return stars;
    }

    return (
        <div className='feedback-container'>
            <div className='feedback-title'>
                Beta User Feedback
            </div>
            <div className='feedback-subtitle'>
                Don't just take our word for it! Here's what our users have to say.
            </div>



            <div className='feedback-slider-container'>
                <Slider {...settings}>

                    <div className='feedback-card'>
                        <div className='feedback-card-content'>
                            <div className='feedback-card-profile'>
                                <div>
                                    <GilbertPfpImage
                                        className='feedback-card-profile-picture'
                                    />

                                </div>
                                <div className='feedback-card-profile-name'>
                                    Gilbert M.
                                </div>
                            </div>
                            <div className='feedback-card-text'>
                                Game changer. Could't have gotten my masters without it.
                            </div>
                        </div>
                        <div className='feedback-card-review'>
                            <BerkeleyLogoImage
                                width={86.737}
                                height={23.684}
                                style={{ flexShrink: 0 }}

                            />
                            <div className='star-reviews'>
                                {renderGoldenStars()}
                            </div>

                        </div>
                    </div>

                    <div className='feedback-card'>
                        <div className='feedback-card-content'>
                            <div className='feedback-card-profile'>
                                <div>
                                    <HaerinPfpImage
                                        className='feedback-card-profile-picture'
                                    />

                                </div>
                                <div className='feedback-card-profile-name'>
                                    Haerin
                                </div>
                            </div>
                            <div className='feedback-card-text'>
                                이 AI 기반 논문 작성 사이트는 훌륭합니다! 스마트한 문법 체크, 연구 지원, 간편한 인용으로 작성을 간소화합니다.
                                사용자 친화적 인터페이스로 글쓰기가 즐겁고 생산성이 향상됩니다!
                            </div>
                        </div>
                        <div className='feedback-card-review'>
                            <UofTLogoImage
                                width={85}
                                height={30.305}
                                style={{ flexShrink: 0 }}

                            />
                            <div className='star-reviews'>
                                {renderGoldenStars()}
                            </div>

                        </div>
                    </div>


                    <div className='feedback-card'>
                        <div className='feedback-card-content'>
                            <div className='feedback-card-profile'>
                                <div>
                                    <ClairePfpImage
                                        className='feedback-card-profile-picture'
                                    />

                                </div>
                                <div className='feedback-card-profile-name'>
                                    Claire Hsu
                                </div>
                            </div>
                            <div className='feedback-card-text'>
                                One of the standout features is the AI's ability to recommend relevant research and literature based on my topic.
                                This has saved me countless hours of searching and has enriched my papers with high-quality sources.
                            </div>
                        </div>
                        <div className='feedback-card-review'>
                            <UOttawaLogoImage
                                width={86.737}
                                height={23.684}
                                style={{ flexShrink: 0 }}

                            />
                            <div className='star-reviews'>
                                {renderGoldenStars()}
                            </div>

                        </div>
                    </div>

                    <div className='feedback-card'>
                        <div className='feedback-card-content'>
                            <div className='feedback-card-profile'>
                                <div>
                                    <WaynePfpImage
                                        className='feedback-card-profile-picture'
                                    />
                                </div>
                                <div className='feedback-card-profile-name'>
                                    Wayne
                                </div>
                            </div>
                            <div className='feedback-card-text'>
                                Using this AI-powered paper writing site has been a game-changer for me.
                                It helps with grammar, research, and citations, making writing so much easier.
                                The interface is super user-friendly, and it really boosts my productivity.
                            </div>
                        </div>
                        <div className='feedback-card-review'>
                            <NYULogoImage
                                width={86.737}
                                height={23.684}
                                style={{ flexShrink: 0 }}

                            />
                            <div className='star-reviews'>
                                {renderGoldenStars()}
                            </div>

                        </div>
                    </div>

                    <div className='feedback-card'>
                        <div className='feedback-card-content'>
                            <div className='feedback-card-profile'>
                                <div>
                                    <ProfileImage
                                        className='feedback-card-profile-picture'
                                    />

                                </div>
                                <div className='feedback-card-profile-name'>

                                    Daniel
                                </div>

                            </div>
                            <div className='feedback-card-text' >
                                I love Litinsight! It has revolutionized the way I approach my research and writing.
                                The AI's ability to provide relevant recommendations and assist with grammar and citations is truly remarkable.
                            </div>
                        </div>
                        <div className='feedback-card-review'>
                            <BerkeleyLogoImage
                                width={86.737}
                                height={23.684}
                                style={{ flexShrink: 0 }}

                            />
                            <div className='star-reviews'>
                                {renderGoldenStars()}
                            </div>

                        </div>
                    </div>



                </Slider>
            </div >


        </div >


    );
}