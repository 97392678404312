import './App.css';
import Home from './Home/Home';
import BackToTopButton from './Shared/BackToTopButton/BackToTopButton';
import { Route, BrowserRouter as Router, Routes} from "react-router-dom"

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<><Home /> <BackToTopButton /></> }  />
          
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
