import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function WriteFileIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props} viewBox='0 0 32 32'>
            <mask id="mask0_469_7182" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <path d="M0 0H32V32H0V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_469_7182)">
                <path d="M6.78315 25.6614C6.38144 25.7332 6.04982 25.6328 5.78829 25.3601C5.52675 25.0875 5.43188 24.7503 5.50365 24.3486L6.44982 19.8256L11.3062 24.7153L6.78315 25.6614ZM12.6959 23.6666L7.49849 18.4692L20.5575 5.42305C20.9729 5.00768 21.4699 4.79999 22.0485 4.79999C22.6271 4.79999 23.1241 5.00768 23.5395 5.42305L25.742 7.59225C26.1574 8.00763 26.3651 8.51019 26.3651 9.09992C26.3651 9.68965 26.1574 10.1922 25.742 10.6076L12.6959 23.6666Z" fill="#FF9494" />
            </g>
        </SvgIconRoot>
    );
}