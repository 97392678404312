import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function CloseSmallIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props}>
            <path d="m336-280-56-56 144-144-144-143 56-56 144 144 143-144 56 56-144 143 144 144-56 56-143-144-144 144Z"/>
        </SvgIconRoot>
    );
}
