import { twMerge } from 'tailwind-merge';

export type ClassValue =
    | ClassArray
    | ClassDictionary
    | string
    | number
    | null
    | undefined
    | boolean;

export interface ClassDictionary {
    [id: string]: any;
}

export interface ClassArray extends Array<ClassValue> {}

export function cx(...classes: ClassValue[]): string {
    const handleArgument = (arg: ClassValue) => {
        if (typeof arg === 'string') {
            return arg;
        } else if (Array.isArray(arg)) {
            var newstr = '';
            for (const elem of arg) {
                newstr += (newstr && ' ') + handleArgument(elem);
            }
            return newstr;
        }
        return '';
    }

    var i = 0,
        tmp,
        str = '',
        len = arguments.length;
    for (; i < len; i++) {
        if ((tmp = arguments[i])) {
            const output = handleArgument(tmp);
            if (output) {
                str += (str && ' ') + output;
            }
        }
    }
    return twMerge(str);
}
