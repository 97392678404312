import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function ReadFileIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props} viewBox='0 0 32 32'>
            <mask id="mask0_467_7048" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <path d="M0 0H32V32H0V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_467_7048)">
                <path d="M16.7905 23.3993C17.7277 22.9291 18.6877 22.5741 19.6703 22.3342C20.653 22.0944 21.6583 21.9745 22.6863 21.9745C23.4517 21.9745 24.1821 22.0125 24.8775 22.0885C25.5729 22.1646 26.2046 22.3319 26.7727 22.5905C26.8682 22.6297 26.9557 22.6238 27.0353 22.5729C27.1149 22.5219 27.1546 22.4416 27.1546 22.3319V9.05687C27.1546 8.98635 27.1347 8.92365 27.095 8.86877C27.0552 8.8139 26.9876 8.76686 26.8921 8.72768C26.168 8.48629 25.4774 8.30289 24.8202 8.17749C24.163 8.05211 23.4517 7.98942 22.6863 7.98942C21.6376 7.98942 20.616 8.11913 19.6214 8.37855C18.6268 8.63798 17.6832 9.0271 16.7905 9.54593V23.3993ZM15.9837 25.6C14.9621 24.995 13.8927 24.5044 12.7756 24.1282C11.6585 23.752 10.4937 23.5639 9.28114 23.5639C8.60644 23.5639 7.92975 23.6109 7.25107 23.7049C6.57238 23.799 5.91477 23.9557 5.27825 24.1752C4.77382 24.3554 4.30121 24.2982 3.86041 24.0035C3.41962 23.7088 3.19922 23.2888 3.19922 22.7433V8.82647C3.19922 8.502 3.28873 8.20379 3.46776 7.93183C3.64678 7.65988 3.88668 7.46277 4.18744 7.34051C4.99899 7.01133 5.83124 6.7723 6.68418 6.6234C7.5371 6.47448 8.40275 6.40002 9.28114 6.40002C10.4683 6.40002 11.6275 6.54737 12.7589 6.84207C13.8903 7.13674 14.9653 7.57093 15.9837 8.14464C17.0228 7.59131 18.1029 7.16221 19.224 6.85735C20.3451 6.55247 21.4991 6.40002 22.6863 6.40002C23.5647 6.40002 24.4303 6.47448 25.2832 6.6234C26.1362 6.7723 26.9684 7.01133 27.78 7.34051C28.0807 7.46277 28.3258 7.65988 28.5152 7.93183C28.7045 8.20379 28.7992 8.502 28.7992 8.82647V22.7433C28.7992 23.2684 28.6329 23.6896 28.3003 24.0071C27.9678 24.3245 27.6137 24.4134 27.2381 24.2739C26.5061 24.0012 25.7602 23.8147 25.0004 23.7144C24.2406 23.614 23.4692 23.5639 22.6863 23.5639C21.4737 23.5639 20.3089 23.752 19.1918 24.1282C18.0747 24.5044 17.0053 24.995 15.9837 25.6Z" fill="#FFB800" />
            </g>
        </SvgIconRoot>
    );
}
