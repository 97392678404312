import React from "react";
import ImageRoot, {ImageRootProps} from "../ImageRoot.tsx";


export default function ProfileImage(props: ImageRootProps) {
    return (
        <ImageRoot {...props} 
            src="/images/user.png"
            alt="profile"
        >   
        </ImageRoot>
    );
}
