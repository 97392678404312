import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from '../SvgIconRoot.tsx';


export default function ArrowUpIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props}>
            <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"/>
        </SvgIconRoot>
    );
}