import React from 'react';
import './WriteCard.css';
import WriteCardImage from '../../../Shared/images/lib/WriteCardImage.tsx';

const WriteCard = () => {
    return (
        <header className='write-card-container'>
            {/* Your header content goes here */}
            <div className='write-card-content'>

                <div className='write-card-grid-left'>
                    <WriteCardImage className="write-card-image"
                    />
                </div>
                <div className='write-card-grid-right'>
                    <div className='write-card-text'>
                        <div className={'write-card-title'}>
                            Write Smarter and more Professional
                        </div>
                        <div className='write-card-info'>
                            Transform your initial drafts and ideas into polished texts with AI-generated writing.
                            Enhance your work with writing improvement and accurate citation assistance.
                            Utilize your own materials as references.
                        </div>

                    </div>

                </div>
            </div>
        </header>
    );
};

export default WriteCard;