import React from "react";
import ImageRoot, {ImageRootProps} from "../ImageRoot.tsx";


export default function HarvardLogoImage(props: ImageRootProps) {
    return (
        <ImageRoot {...props} 
            src="/images/Harvard-logo.svg"
            alt="logo">
            
        </ImageRoot>
    );
}
