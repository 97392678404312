import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function FacebookIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props} width="41" height="40" viewBox="0 0 41 40" >

            <mask id="mask0_2691_12314" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
                <rect x="0.5" width="40" height="40" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2691_12314)">
                <path d="M24.585 12.3808H26.2176V9.33319C26.2176 9.33319 25.3197 8.57129 22.9523 8.57129C19.687 8.57129 18.0544 10.857 18.0544 13.9046V16.1903H14.7891V19.9999H18.0544V31.4284H22.136V19.9999H25.4013L26.2176 16.1903H22.136V13.9046C22.136 12.7618 22.838 12.3808 24.585 12.3808Z" fill="white" />
            </g>

        </SvgIconRoot>
    );
}