import React from 'react';
import './ReadCard.css';
import ReadCardImage from '../../../Shared/images/lib/ReadCardImage.tsx';

const ReadCard = () => {
    return (
        <header className='read-card-container'>
            {/* Your header content goes here */}
            <div className='read-card-content'>
                <div className='read-card-grid-left'>
                    <div className='read-card-text'>
                        <div className={'read-card-title'}>
                            Read Faster and more Accurate
                        </div>
                        <div className='read-card-info'>
                            Engage with any PDF through our domain-aware and context-sensitive AI assistance,
                            designed to deepen your understanding of the material.
                        </div>

                    </div>

                </div>
                <div className='read-card-grid-right'>
                    <ReadCardImage className="read-card-image"
                    />
                </div>

            </div>
        </header>
    );
};

export default ReadCard;