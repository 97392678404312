import React, { ComponentPropsWithRef, forwardRef } from 'react';

export interface ImageRootProps extends ComponentPropsWithRef<'img'> {
    className?: string;
    src?: string;
    alt?: string;
    children?: React.ReactNode;
}

const ImageRoot = forwardRef<HTMLImageElement, ImageRootProps>(
    (props, ref) => {
        const {
            className,
            src,
            alt,
            children,
            ...rootProps
        } = props;

        return (
            <img
                className={className}
                src={src}
                alt={alt}
                ref={ref}
                {...rootProps}
            >
                {children}
            </img>
        );
    },
);

export default ImageRoot;
