import React from 'react';
import { useEffect, useState } from 'react';
import './BackToTopButton.css'
import ArrowUpIcon from '../icons/lib/ArrowUpIcon.tsx';

export default function BackToTopButton() {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true)
            }
            else {
                setBackToTopButton(false)
            }
        })
    })

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (
        <div className='back-to-top-button-container'>
            {backToTopButton &&
                <button className='back-to-top-button' onClick={scrollUp}>
                    <ArrowUpIcon
                        width={24}
                        height={24}

                    />
                </button>
            }
        </div>
    )
}