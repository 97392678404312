import React from 'react';
import './ProductsCard.css';
import WriteFileIcon from '../../../Shared/icons/lib/WriteFileIcon.tsx';
import ReadFileIcon from '../../../Shared/icons/lib/ReadFileIcon.tsx';
import SearchIcon from '../../../Shared/icons/lib/SearchIcon.tsx';
import HarvardLogoImage from '../../../Shared/images/lib/HarvardLogoImage.tsx';
import BerkeleyLogoImage from '../../../Shared/images/lib/BerkeleyLogoImage.tsx';
import UofTLogoImage from '../../../Shared/images/lib/UofTLogoImage.tsx';
import NYULogoImage from '../../../Shared/images/lib/NYULogoImage.tsx';
import UPennLogoImage from '../../../Shared/images/lib/UPennLogoImage.tsx';
import UOttawaLogoImage from '../../../Shared/images/lib/UOttawaLogoImage.tsx';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function ProductsCard() {

    const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        slidesToShow: 6,
        infinite: true,
        responsive: [
        
            {
                breakpoint: 1000,
                settings: {
                    dots: false,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    arrows: false,
                    autoplay: true,
                    slidesToShow: 3,
                    infinite: true,
                }
            }
        ]
    };

    return (
        <div className='products-container'>
            <div className='products-cards'>
                <div className='product-card'>
                    <WriteFileIcon
                        width={40}
                        height={40}
                    />
                    <div className='product-card-title'>
                        <h1>WRITE</h1>
                    </div>
                    <div className='product-card-text'>
                        <p>Write smarter and more professional</p>
                    </div>

                </div>
                <div className='product-card'>
                    <ReadFileIcon
                        width={40}
                        height={40}
                    />
                    <div className='product-card-title'>
                        <h1>READ</h1>
                    </div>
                    <div className='product-card-text'>
                        <p>Read faster and more accurate</p>
                    </div>

                </div>
                <div className='product-card'>
                    <SearchIcon
                        width={40}
                        height={40}
                        fill={'#2A4FE7'}
                    />
                    <div className='product-card-title'>
                        <h1>RESEARCH</h1>
                    </div>
                    <div className='product-card-text'>
                        <p>Research with seamless connected files</p>
                    </div>

                </div>

            </div>
            <div className='university-section'>
                <h3 className='university-section-title'>
                    Designed and developed by researchers from
                </h3>

                <div className='image-slider-container'>
                    <Slider {...settings}>
                        <HarvardLogoImage style={{
                            maxWidth: 169.888,
                            maxHeight: 102.559,
                            flexShrink: 0, 
                        }} />
                        <BerkeleyLogoImage style={{
                            maxWidth: 239.566,
                            maxHeight: 64.197,
                            flexShrink: 0
                        }} />
                        <UofTLogoImage style={{
                            maxWidth: 183.75,
                            maxHeight: 65.625,
                            flexShrink: 0
                        }} />
                        <NYULogoImage style={{
                            maxWidth: 149,
                            maxHeight: 49,
                            flexShrink: 0
                        }} />
                        <UPennLogoImage style={{
                            maxWidth: 154.875,
                            maxHeight: 86.625,
                            flexShrink: 0
                        }} />
                        <UOttawaLogoImage style={{
                            maxWidth: 176.75,
                            maxHeight: 47.25,
                            flexShrink: 0
                        }} />
                    </Slider>
                </div>


            </div>

        </div >
    );
}