import React, { useState } from 'react';
import './WaitlistModal.css';
import { Modal } from "react-bootstrap";
import CloseSmallIcon from '../../../Shared/icons/lib/CloseSmallIcon.tsx';

export default function WaitlistModal({ show, onClose }) {
    const [status, setStatus] = useState('pending'); // success | fail | pending
    const [email, setEmail] = useState(''); // Assuming you're collecting emails

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Make a POST request with the form data
        try {
            const response = await fetch('https://docker.knowlecy.ai:7601/waitlists', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setStatus('success');

            } else {
                // Handle server errors or invalid responses
                setStatus('fail');
                throw new Error('Failed to submit the form');
            }
        } catch (error) {
            setStatus('fail');
            console.error('There was an error submitting the form:', error);
        }
    };

    if (!show) {
        return null;
    }

    return (
        <Modal show={show} onHide={onClose} className='modal-container'>
            <Modal.Header className='modal-header'>
                <button onClick={onClose} className='close-button'>
                    <CloseSmallIcon 
                        width={24}
                        height={24}/>
                </button>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Modal.Title className='title'>Join the Waitlist Today!</Modal.Title>
                {status === 'pending' && (
                    <form onSubmit={handleSubmit} className='email-input-form'>
                        <input
                            className='email-input-box'
                            placeholder="Enter your email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button className="email-input-button" type="submit">Waitlist</button>
                    </form>
                )}
                {status === 'success' && (

                    <div className='status-message'>You have successfully been waitlisted! ✅</div>
                )}
                {status === 'fail' && (
                    <div className='status-message'>Failed! Please try again later. ❌</div>
                )
                }
                <p className='footer-disclaimer'>
                    By waitlisting, you agree to Knowlecy sending marketing communications,
                    as described in the Privacy and Cookie policy.
                </p>
            </Modal.Body>

        </Modal>
    )

}