import React, { ComponentPropsWithRef, forwardRef } from 'react';

export interface SvgIconRootProps extends ComponentPropsWithRef<'svg'> {
    width?: number | string;
    height?: number | string;
    viewBox?: string;
    children?: React.ReactNode;
}

const SvgIconRoot = forwardRef<SVGSVGElement, SvgIconRootProps>(
    (props, ref) => {
        const {
            width = '1em',
            height = '1em',
            viewBox = '0 -960 960 960',
            children,
            ...rootProps
        } = props;

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox}
                width={width}
                height={height}
                fill="currentColor"
                ref={ref}
                {...rootProps}
            >
                {children}
            </svg>
        );
    },
);

export default SvgIconRoot;
