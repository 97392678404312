import React from 'react';
import './ChatCard.css';
import ChatCardImage from '../../../Shared/images/lib/ChatCardImage.tsx';

const ChatCard = () => {
    return (
        <header className='chat-card-container'>
            {/* Your header content goes here */}
            <div className='chat-card-content'>

                <div className='chat-card-grid-left'>
                    <ChatCardImage className="chat-card-image"
                    />
                </div>
                <div className='chat-card-grid-right'>
                    <div className='chat-card-text'>
                        <div className={'chat-card-title'}>
                            Chat built for Academia
                        </div>
                        <div className='chat-card-info'>
                            Ask any academic question and receive answers backed by reliable resources. 
                            Discover what to learn next and spark innovative research ideas. 
                            Create notes from the answers you found useful.
                        </div>
                        
                    </div>

                </div>
            </div>
        </header>
    );
};

export default ChatCard;