import React from "react";
import ImageRoot, {ImageRootProps} from "../ImageRoot.tsx";


export default function UOttawaLogoImage(props: ImageRootProps) {
    return (
        <ImageRoot {...props} 
            src="/images/UOttawa-logo.svg"
            alt="logo">
            
        </ImageRoot>
    );
}
