import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function LinkedInIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props} width="41" height="40" viewBox="0 0 41 40" >


            <mask id="mask0_2691_12318" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
                <rect x="0.5" width="40" height="40" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2691_12318)">
                <path d="M16.7228 28.5714V15.5026H13.3555V28.5714H16.7228Z" fill="white" />
                <path d="M15.0391 10C14.0289 10 13.3555 10.736 13.3555 11.6989C13.3555 12.6619 14.0289 13.4392 15.0391 13.4392C16.0942 13.4392 16.7228 12.6619 16.7228 11.6989C16.6835 10.736 16.0493 10 15.0391 10Z" fill="white" />
                <path d="M25.7024 28.5714H29.0698V21.1841C29.0698 17.3804 27.2626 15.5026 25.0345 15.5026C22.857 15.5026 21.7738 17.2635 21.7738 17.2635V15.5026H18.4065V28.5714H21.7738V21.6931C21.7738 20.3312 22.3351 18.9143 23.6932 18.9143H23.7381C25.1412 18.9418 25.7024 20.3175 25.7024 21.6931V28.5714Z" fill="white" />
            </g>

        </SvgIconRoot>
    );
}