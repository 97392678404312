import React from 'react';
import SvgIconRoot, { SvgIconRootProps } from './../SvgIconRoot.tsx';


export default function GoldenStarIcon(props: SvgIconRootProps) {
    return (
        <SvgIconRoot {...props}  viewBox="0 0 24 24" fill="none">
            <path d="M11.0676 2.40576C11.3976 1.55427 12.6024 1.55427 12.9324 2.40576L14.7999 7.22423C14.9418 7.5903 15.2848 7.83949 15.6768 7.8613L20.8365 8.14844C21.7483 8.19918 22.1206 9.34501 21.4128 9.922L17.4073 13.1871C17.1029 13.4352 16.9719 13.8384 17.0723 14.2179L18.3937 19.2138C18.6272 20.0967 17.6525 20.8049 16.885 20.31L12.5419 17.5094C12.212 17.2967 11.788 17.2967 11.4581 17.5094L7.11499 20.31C6.34751 20.8049 5.3728 20.0967 5.60631 19.2138L6.92767 14.2179C7.02806 13.8384 6.89706 13.4352 6.59274 13.1871L2.5872 9.922C1.87937 9.34501 2.25167 8.19918 3.16347 8.14844L8.3232 7.8613C8.7152 7.83949 9.05817 7.5903 9.20006 7.22423L11.0676 2.40576Z" fill="#FFB800"/>
        </SvgIconRoot>
    );
}
