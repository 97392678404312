import React from "react";
import ImageRoot, {ImageRootProps} from "../ImageRoot.tsx";


export default function WriteCardImage(props: ImageRootProps) {
    return (
        <ImageRoot {...props} 
            src="/images/WriteImageSVG.svg"
            alt="logo">
            
        </ImageRoot>
    );
}
