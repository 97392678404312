import React from 'react';
import Header from './Components/Header/Header';
import ProductsCard from './Components/ProductsCard/ProductsCard';
import './Home.css';
import ChatCard from './Components/ChatCard/ChatCard';
import ReadCard from './Components/ReadCard/ReadCard';
import WriteCard from './Components/WriteCard/WriteCard';
import Footer from './Components/Footer/Footer';
import UserFeedbackCard from './Components/UserFeedbackCard/UserFeedbackCard';

const Home = () => {
    return (
        <div className='home-container'>
            <Header />
            <ProductsCard />
            <ChatCard />
            <ReadCard />
            <WriteCard />
            <UserFeedbackCard />
            <Footer />
            
        </div>
            

       

    )
}

export default Home;