import React, { useState } from 'react';
import './Footer.css';
import YoutubeIcon from '../../../Shared/icons/lib/YoutubeIcon.tsx';
import FacebookIcon from '../../../Shared/icons/lib/FacebookIcon.tsx';
import LinkedInIcon from '../../../Shared/icons/lib/LinkedInIcon.tsx';
import BlogIcon from '../../../Shared/icons/lib/BlogIcon.tsx';


const Footer = () => {
    const [email, setEmail] = useState('');
    const currentYear = new Date().getFullYear();
    const [status, setStatus] = useState('pending'); // success | fail | pending

 

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Make a POST request with the form data
        try {
            const response = await fetch('https://docker.knowlecy.ai:7601/subscriptions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setStatus('success');

            } else {
                // Handle server errors or invalid responses
                setStatus('fail');
                throw new Error('Failed to submit the form');
            }
        } catch (error) {
            setStatus('fail');
            console.error('There was an error submitting the form:', error);
        }
    };

    return (
        <footer className='footer-container'>
            <div className='footer-content'>
                <h1 className='footer-title'>
                    We’d love to share our news with you!
                </h1>
                {status === 'pending' && (
                    <form onSubmit={handleSubmit} className='email-input-form'>
                        <input
                            className='email-input-box'
                            placeholder="Enter your email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button className="email-input-button" type="submit">Subscribe</button>
                    </form>
                )}
                {status === 'success' && (

                    <div className='status-message'>Thank you for subscribing! ✅</div>
                )}
                {status === 'fail' && (
                    <div className='status-message'>Failed! Please try again later. ❌</div>
                )
                }
                <p className='footer-disclaimer'>
                    By subscribing, you agree to Knowlecy sending marketing communications,
                    as described in the Privacy and Cookie policy.
                </p>
                <div className='social-media-icons'>
                    <a href="https://www.youtube.com/@Knowlecy" target="_blank" rel="noopener noreferrer">
                        <YoutubeIcon
                            className='social-media-icon'
                            width={40}
                            height={40}
                            style={{ backgroundColor: '#DE3E3E' }}
                        />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61553662206068" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon
                            className='social-media-icon'
                            width={40}
                            height={40}
                            style={{ backgroundColor: '#2A4FE7' }}
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/knowlecy" target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon
                            className='social-media-icon'
                            width={40}
                            height={40}
                            style={{ backgroundColor: '#1189F8' }}
                        />
                    </a>
                    <a href="https://blog.knowlecy.com/" target="_blank" rel="noopener noreferrer">
                        <BlogIcon
                            className='social-media-icon'
                            width={40}
                            height={40}
                            style={{ backgroundColor: 'white' }}
                        />
                    </a>
                </div>
            </div>
            <div className='footer-copyright'>
                {currentYear} Knowlecy © copyright
            </div>

        </footer>
    );

}

export default Footer;